const PATH_ADN = '/advertising/adn';
const SOURCE = 'script';
const ACTION_TYPE = 'cleanAndSend';

const STATUS_SUCCESS = 'success';
const STATUS_ERROR = 'error';

const trackEvent = (trackEventData: TrackEventData, path = PATH_ADN) => {
  if (window.melidata) {
    window.melidata(ACTION_TYPE, 'event', {
      path,
      event_data: trackEventData,
    });
  }
};

const buildDefaultEvent = (banner?: Element): TrackEventData => ({
  status: STATUS_SUCCESS,
  source: SOURCE,
  params: {
    placement: banner?.getAttribute('data-placement') || '',
    page: banner?.getAttribute('data-page') || '',
    slot_id: banner?.getAttribute('data-slot_id') || '',
  },
});

export const trackBanner = (banner?: Element, errorMsg?: string, status = false) => {
  const bannerEventData = buildDefaultEvent(banner);

  if (errorMsg) {
    bannerEventData.status = STATUS_ERROR;
    bannerEventData.reason = errorMsg;
    const event = new CustomEvent('adn-banner-empty', {
      detail: { message: errorMsg },
      bubbles: true,
      composed: true,
    });
    banner?.dispatchEvent(event);
    banner?.setAttribute('style', 'display:none;');
  }

  if (status) {
    bannerEventData.status = STATUS_SUCCESS;
  }

  trackEvent(bannerEventData);
};
