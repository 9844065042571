const importPolyfill = (callback: () => void) => {
  const s = document.createElement('script');

  if ('noModule' in s === false || !Promise.allSettled) {
    s.src = 'https://http2.mlstatic.com/frontend-assets/adn-frontend-library/adn-polyfills.js';
    s.defer = true;
    s.onload = callback;
    document.head.appendChild(s);
  } else {
    callback();
  }
};

export default importPolyfill;
