import { getCookie } from './utils/cookies';

const getBaseUrl = (adUnit: string): string => {
  const { hostname } = window.location;
  const domain = hostname.split('.').slice(1).join('.');

  if (!hostname.includes('mercadopago')) {
    return `https://${hostname.replace('dev', 'www')}/adn/api`;
  }

  return `https://placements.${domain}/v1/adn/${adUnit}`;
};

const fetching = (searhParams: URLSearchParams) => new Promise<BannerResponse>((resolve, reject) => {
  const scope = getCookie('adserver-scope');
  const adUnit = searhParams.get('ad_unit') || '';
  if (scope) {
    searhParams.set('scope', scope);
  }
  const request = new XMLHttpRequest();
  request.open('GET', `${getBaseUrl(adUnit)}?${searhParams.toString()}`);

  request.responseType = 'json';
  request.withCredentials = true;

  request.onload = () => {
    if (![200, 204].includes(request?.status)) {
      reject(new Error(`status code ${request?.status} not valid`));
    }
    resolve(request.response as BannerResponse);
  };

  request.onerror = () => {
    reject(new Error('XMLHttpRequest network error'));
  };

  request.send();
});

export default fetching;
