const PLATFORM_IOS = 'ios';
const PLATFORM_ANDROID = 'android';
const PURCHASES_LIST = 'purchases-list';

const DEFAULT_PAGE: string[] = [PURCHASES_LIST];

const DEFAULT_PLATFORM: string[] = [PLATFORM_IOS, PLATFORM_ANDROID];

export const enabledReload = (params: URLSearchParams) => {
  const page = params.get('page') || '';
  const platform = params.get('platform') || '';

  return DEFAULT_PAGE.includes(page) && DEFAULT_PLATFORM.includes(platform);
};
