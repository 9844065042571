const SPONSOR_LANDING = 'sponsor_landing';
const SEARCH = 'search';
const PLATFORM_IOS = 'ios';
const PLATFORM_ANDROID = 'android';
const AUTO_REFRESH_INITIAL = 'initial';
const PURCHASES_STATUS = 'purchases-status';
const PURCHASES_LIST = 'purchases-list';

const DEFAULT_PAGE: string[] = [SPONSOR_LANDING, SEARCH, PURCHASES_STATUS, PURCHASES_LIST];

const DEFAULT_PLATFORM: string[] = [PLATFORM_IOS, PLATFORM_ANDROID];

const ML_ANDROID_MIN_VERSION_FOR_WEBKIT = '10.360.0';
const MP_ANDROID_MIN_VERSION_FOR_WEBKIT = '2.349.0';
const ENABLED_PLATFORMS_FOR_WEBKIT = ['android'];

// eslint-disable-next-line no-useless-escape
const regex = /mercado(libre|pago)\-[a-zA-Z]+(\/|%2F)(\d+\.\d+\.\d+).*/i;


const isVersionGreaterOrEqual = (version: string, controlVersion: string) => {
  const versionComponents = version.split('.').map(Number);
  const controlVersionComponents = controlVersion.split('.').map(Number);

  return versionComponents.some((value, index) => {
    if (value > controlVersionComponents[index]) return true;
    if (value < controlVersionComponents[index]) return false;
    return false;
  }) || versionComponents.every((component, index) => component === controlVersionComponents[index]);
};

export const setDefaultAutoRefresh = (banner: Element, params: URLSearchParams) => {
  const page = params.get('page') || '';
  const platform = params.get('platform') || '';

  if (DEFAULT_PAGE.includes(page) && DEFAULT_PLATFORM.includes(platform)) {
    banner.setAttribute('data-auto_refresh', AUTO_REFRESH_INITIAL);
  }
};

const shouldUseWebkit = () => {
  try {
    const version = window.navigator.userAgent.match(regex)![3];
    const business = window.navigator.userAgent.match(regex)![1].toLowerCase();
    const isMP = business === 'pago';

    const isSupportedPlatform = ENABLED_PLATFORMS_FOR_WEBKIT.includes(MobileWebKit.platform);
    const isMLVersionValid = !isMP && isVersionGreaterOrEqual(version, ML_ANDROID_MIN_VERSION_FOR_WEBKIT);
    const isMPVersionValid = isMP && isVersionGreaterOrEqual(version, MP_ANDROID_MIN_VERSION_FOR_WEBKIT);

    return isSupportedPlatform && (isMLVersionValid || isMPVersionValid);
  } catch (exception) {
    return false;
  }
};

export const listenVisibilityChanges = (callback: () => void) => {
  if (MobileWebKit.isConnected() && shouldUseWebkit()) {
    MobileWebKit.lifecycle.onViewAppeared(() => {
      if (window.adn === 'finished') {
        callback();
      }
    });
    return;
  }

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible' && window.adn === 'finished') {
      callback();
    }
  });
};
